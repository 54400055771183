import React, { useState } from "react";
import PlayerDetails from "./PlayerDetails";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { formatToNumber, formatToPercentage } from "lib/utils";
import TableSkeleton from "components/skeletons/Table";

const PlayerSide = ({ playersData, statsLoading, statsError }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [rank, setRank] = useState(0);
  const filterPerformance = useSelector(
    (state) => state.filter_performance_overwatch
  );

  const handleShowDetailsButton = (data, i) => {
    setPlayerData(data);
    setShowDetails(true);
    setRank(i + 1);
  };
  if (statsLoading) {
    return (
      <TableSkeleton
        header={[
          "Team",
          "Name",
          "Role(s)",
          "Price $",
          "Infamy Score",
          "Map Wins",
          "Map Loss",
          "Sweep Bonus",
          "Stage Points",
          "Point Value",
          "Pick Rate",
        ]}
      />
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  return (
    <div className=" flex flex-col gap-8">
      <h1 className=" text-2xl">Players</h1>
      {showDetails ? (
        <ErrorBoundary
          fallback={<div>Error:Player Details Failed to Load</div>}
        >
          <PlayerDetails
            playerData={playerData}
            callBack={setShowDetails}
            rank={rank}
          />
        </ErrorBoundary>
      ) : (
        <></>
      )}

      {/* New Data Points: Map Wins, Map Loss, Sweep Bonus, Stage Points, POTM, Finals MVP, Team Points
Pick Rate, Picks */}
      <div className="overflow-x-auto">
        <table className="text-left w-[2000px] overflow-auto ">
          <tr className="secondary-font">
            <th>Team</th>
            <th>Name</th>
            <th>Role(s)</th>
            <th className="hidden lg:table-cell">Price $</th>
            <th>Infamy Score</th>
            <th className="hidden lg:table-cell">Map Wins</th>
            <th className="hidden lg:table-cell">Map Loss</th>
            <th className="hidden lg:table-cell">Sweep Bonus</th>
            <th className="hidden lg:table-cell">Stage Points</th>
            <th className="hidden lg:table-cell">POTM</th>
            <th className="hidden lg:table-cell">Finals MVP</th>
            <th className="hidden lg:table-cell">Team Points</th>
            <th className="hidden lg:table-cell">Point Value</th>
            <th className="hidden lg:table-cell">Pick Rate</th>
          </tr>
          {playersData?.map((player, index) => (
            <tr
              key={player?.pro_player_id}
              onClick={() => handleShowDetailsButton(player, index)}
              className=" cursor-pointer"
            >
              <td>
                <div className="flex gap-4 items-center">
                  <img
                    src={`${player?.team_logo_url}`}
                    alt={`${player?.team_name}`}
                    width={24}
                  />
                  <p className="hidden sm:table-cell">{player.team_name}</p>
                </div>
              </td>
              <td>
                <div className=" flex gap-2">
                  <img
                    src={`${player?.pro_player_image_url}`}
                    alt={`${player?.pro_player}`}
                    width={24}
                    className="hidden sm:table-cell"
                  />
                  <p>{player?.pro_player}</p>
                  <>
                    {index === 0
                      ? "🥇"
                      : index === 1
                      ? "🥈"
                      : index === 2
                      ? "🥉"
                      : ""}
                  </>
                </div>
              </td>

              <td>
                <p>
                  {player?.role?.map((r) => (
                    <span key={r}> {r}</span>
                  ))}
                </p>
              </td>
              <td className="text-[#f9d25f] secondary-font text-lg hidden lg:table-cell font-normal">
                ${formatToNumber(player?.price)}
              </td>
              <td className="text-[#f9d25f] secondary-font font-medium text-lg">
                {formatToNumber(player?.infamy_score)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {/* map_win	map_loss	sweep_bonus	stage_points	player_of_the_match	finals_mvp	team_points */}
                {formatToNumber(player?.map_win)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.map_loss)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.sweep_bonus)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.stage_points)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.player_of_the_match)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.finals_mvp)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.team_points)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToNumber(player?.point_value, 2)}
              </td>
              <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                {formatToPercentage(player?.pick_rate)}
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default PlayerSide;
